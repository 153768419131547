<template>
  <div id="keywords">
    <table class="table" v-loading.lock="loading">
      <thead>
        <tr>
          <td>监测关键词</td>
          <td>关键词投放记录</td>
          <td>
            <div class="icon" @click="sortFn">
              添加时间
              <div class="icon-box">
                <img
                  class="icon-bottom"
                  v-if="sort == 'desc'"
                  src="./../../../../assets/img/collection/icon_bottom_hot.png"
                  alt=""
                />
                <img
                  class="icon-bottom"
                  v-if="sort == '' || sort == 'asc'"
                  src="./../../../../assets/img/collection/icon_bottom.png"
                  alt=""
                />
                <img
                  class="icon-top"
                  v-if="sort == 'asc'"
                  src="./../../../../assets/img/collection/icon_top_hot.png"
                  alt=""
                />
                <img
                  class="icon-top"
                  v-if="sort == '' || sort == 'desc'"
                  src="./../../../../assets/img/collection/icon_top.png"
                  alt=""
                />
              </div>
            </div>
          </td>
          <td width="100">操作</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, index) in data" :key="index">
          <td class="canClick" @click="detailFn(el.word)">{{ el.word }}</td>
          <td>
            <div class="keywords-box">
              <div class="kb-left">
                <div class="kbr-box">
                  {{ el.total > 100000 ? '100000+' : el.total }}
                  <i>全部</i>
                </div>
              </div>
              <div class="kb-line"></div>
              <div class="kb-right">
                <div class="kbr-box">
                  <span>{{ el.total_m1 > 100000 ? '100000+' : el.total_m1 }}</span>
                  <i>近1个月</i>
                </div>
                <div class="kbr-box">
                  <span>{{ el.total_d7 > 100000 ? '100000+' : el.total_d7 }}</span>
                  <i>近七天</i>
                </div>
              </div>
            </div>
          </td>
          <td>{{ el.add_time }}</td>
          <td>
            <a href="javascript:;" class="click-a" @click="cancleFn(el.word)"
              >取消监测</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <NoData title="暂无监测竞品" v-if="!loading && data.length == 0" />
    <Page
      :config="config"
      @pageChange="pageChange"
      @limitChange="limitChange"
    />
  </div>
</template>

<script>
import { get_monitor_word_list, cancel_monitor } from "@/api/monitor";
export default {
  data() {
    return {
      config: {
        total: 0,
        count: 5,
        sizes: [10, 20, 30, 40, 50],
        page: 1,
        limit: 10,
      },
      sort: "desc",
      data: [],
      loading: true,
      firstLoad: false,
    };
  },
  props: {
    platArr: {
      type: Array,
      default: [],
    },
    query_str: {
      type: String,
      default: null,
    },
  },
  created() {
    this.init();
  },
  methods: {
    detailFn(word) {
      this.$router.push({
        path: '/box/search/findAdvertising',
        query: {
          word: word
        }
      });
    },
    pageChange(val) {
      this.config.page = val;
      this.init();
    },
    limitChange(val) {
      this.config.page = 1;
      this.config.limit = val;
      this.init();
    },
    init() {
      this.loading = true;
      get_monitor_word_list({
        page: this.config.page,
        limit: this.config.limit,
        sort: this.sort,
        query_str: this.query_str,
      }).then((res) => {
        let { result_code, message, data } = res.data;
        if (result_code == 0) {
          this.loading = false;
          this.firstLoad = true;
          this.data = data.rows;
          this.config.total = Number(data.cnt);
          this.$emit("allNumKeywords", this.config.total);
        } else {
          this.alert(message, "error");
        }
      });
    },
    sortFn() {
      if (this.sort == "desc") {
        this.sort = "asc";
      } else if (this.sort == "asc") {
        this.sort = "";
      } else {
        this.sort = "desc";
      }
      this.init();
    },
    cancleFn(digest) {
      cancel_monitor({
        keyword: digest,
        type: 2,
      }).then((res) => {
        let { result_code, message, data } = res.data;
        if (result_code == 0) {
          this.alert("取消监测成功", "success");
          this.init();
        } else {
          this.alert("取消监测失败，请重试", "error");
        }
      });
    },
    // message提示框稍微包一下
    alert(txt, type) {
      this.$message.closeAll();
      this.$message({
        message: txt,
        type: type,
      });
    },
  },
};
</script>

<style scoped lang="scss" src="@/views/box/monitor/list/keywords.scss"></style>
