<template>
  <div id="product">
    <table class="table" v-loading.lock="loading">
      <thead>
        <tr>
          <td width="20%">监测竞品</td>
          <td width="30%">当前推广平台</td>
          <td width="10%">推广域名</td>
          <td width="10%">推广记录</td>
          <td width="20%">
            <div class="icon" @click="sortFn">
              添加时间
              <div class="icon-box">
                <img
                  class="icon-bottom"
                  v-if="sort == 'desc'"
                  src="./../../../../assets/img/collection/icon_bottom_hot.png"
                  alt=""
                />
                <img
                  class="icon-bottom"
                  v-if="sort == '' || sort == 'asc'"
                  src="./../../../../assets/img/collection/icon_bottom.png"
                  alt=""
                />
                <img
                  class="icon-top"
                  v-if="sort == 'asc'"
                  src="./../../../../assets/img/collection/icon_top_hot.png"
                  alt=""
                />
                <img
                  class="icon-top"
                  v-if="sort == '' || sort == 'desc'"
                  src="./../../../../assets/img/collection/icon_top.png"
                  alt=""
                />
              </div>
            </div>
          </td>
          <td width="10%">操作</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, index) in data" :key="index">
          <td>
            <div class="company-logo">
              <span :style="{ background: el.logo_color }"
                ><font>{{ el.logo_img_str }}</font></span
              >
              <a
                href="javascript:;"
                class="table-company text-ellipsis click-a"
                :title="el.company_name"
                @click="detailFn(el.company_name_digest)"
              >
                {{ el.company_name }}</a
              >
            </div>
          </td>
          <td class="platform_box">
            <div
              class="plat"
              v-for="(plat, pIndex) in handlePlatform(el.platform)"
              :key="pIndex"
            >
              <span
                ><img :src="platArr[plat - 1].img" alt="" />{{
                  platArr[plat - 1].label
                }}</span
              >
            </div>
          </td>
          <td>{{ el.link_cnt }}</td>
          <td>{{ el.ad_cnt }}</td>
          <td>{{ el.add_time }}</td>
          <td>
            <a
              href="javascript:;"
              class="click-a"
              @click="cancleFn(el.company_name_digest)"
              >取消监测</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <NoData title="暂无监测竞品" v-if="!loading && data.length == 0" />
    <Page
      :config="config"
      @pageChange="pageChange"
      @limitChange="limitChange"
    />
  </div>
</template>

<script>
import { get_monitor_company_list, cancel_monitor } from "@/api/monitor";
export default {
  data() {
    return {
      config: {
        total: 0,
        count: 5,
        sizes: [10, 20, 30, 40, 50],
        page: 1,
        limit: 10,
      },
      sort: "desc",
      data: [],
      loading: true,
      firstLoad: false,
    };
  },
  props: {
    platArr: {
      type: Array,
      default: [],
    },
    query_str: {
      type: String,
      default: null,
    },
  },
  created() {
    this.init();
  },
  methods: {
    handlePlatform(arr) {
      let list = [];
      for(var i = 0; i < 7; i++) {
        if(arr.includes(i+1)) {
          list.push(i+1);
        } else {
          // list.push(0);
        }
      }
      return list;
    },
    detailFn(digest) {
      window.open("/box/companyDetails?digest=" + digest);
      // this.$router.push({
      //   path: "/box/companyDetails?digest=" + digest,
      // });
    },
    pageChange(val) {
      this.config.page = val;
      this.init();
    },
    limitChange(val) {
      this.config.page = 1;
      this.config.limit = val;
      this.init();
    },
    init() {
      this.loading = true;
      get_monitor_company_list({
        page: this.config.page,
        limit: this.config.limit,
        sort: this.sort,
        query_str: this.query_str,
      }).then((res) => {
        let { result_code, message, data } = res.data;
        if (result_code == 0) {
          this.loading = false;
          this.firstLoad = true;
          this.data = data.rows;
          this.config.total = Number(data.cnt);
          this.$emit("allNumProduct", this.config.total);
        } else {
          this.alert(message, "error");
        }
      });
    },
    sortFn() {
      if (this.sort == "desc") {
        this.sort = "asc";
      } else if (this.sort == "asc") {
        this.sort = "";
      } else {
        this.sort = "desc";
      }
      this.init();
    },
    cancleFn(digest) {
      cancel_monitor({
        keyword: digest,
        type: 1,
      }).then((res) => {
        let { result_code, message, data } = res.data;
        if (result_code == 0) {
          this.alert("取消监测成功", "success");
          this.init();
        } else {
          this.alert("取消监测失败，请重试", "error");
        }
      });
    },
    // message提示框稍微包一下
    alert(txt, type) {
      this.$message.closeAll();
      this.$message({
        message: txt,
        type: type,
      });
    },
  },
};
</script>

<style scoped lang="scss" src="@/views/box/monitor/list/product.scss"></style>
