<template>
  <div id="newest">
    <div class="info_head">
      <div class="title">监测推送</div>
      <div class="binding">
        <div class="wx">
          <div class="notice_title">
            <div class="line"></div>
            微信推送
          </div>
          <span class="status" v-if="!WXNotice">开启微信通知</span>
          <span class="status" v-else>微信通知已开启</span>
          <img src="../../../../assets/img/monitor/newest/wx.png"/>
          <div @click="notice">
            <el-switch v-if="!userInfo.bind_wx" v-model="defaultFalse" @change="changeNotice()" active-color="#1f81f8" :disabled="!userInfo.bind_wx"></el-switch>
            <el-switch v-else v-model="WXNotice" @change="changeNotice()" active-color="#1f81f8" :disabled="!userInfo.bind_wx"></el-switch>
          </div>
          <span v-if="userInfo.bind_wx" style="font-weight: bold; color: #333333">监测动态推送至 {{userInfo.nike_name}}<span class="click" style="color: #ff9a1e" @click="cancelBinding"> 解除绑定 </span></span>
          <span v-if="!userInfo.bind_wx">请先 <span class="click" @mouseenter="show_ewm=true" @mouseleave="show_ewm=false">绑定微信 </span>完善后监测动态将推送至微信</span>
          <div class="ewm" v-show="show_ewm">
            <img :src="ewm_url" alt="">
            <span>使用微信扫码绑定</span>
            <span>开启推送</span>
          </div>
        </div>
        <div class="ms">
          <div class="notice_title">
            <div class="line"></div>
            短信推送
          </div>
          <span class="status" v-if="!MSNotice">开启短信通知</span>
          <span class="status" v-else>短信通知已开启</span>
          <img src="../../../../assets/img/monitor/list/phone.png"/>
          <el-switch v-model="MSNotice" @change="changeMSNotice" active-color="#1f81f8"></el-switch>
          <span>监测动态将发送至手机号{{userInfo.account}}</span>
        </div>
        <div class="text">您监测的竞品/关键词有新的推广记录时，将推送给您。每天上午9点推送一次。</div>
      </div>
    </div>

    <div class="header">
      <h3>
        监测列表
        <div class="header-box">
          <el-input
            type="text"
            ref="input"
            v-model="query_str"
            @keyup.enter.native="handleSearch"
            placeholder="搜索监测竞品/关键词"
            maxlength="100"
          >
            <template #append style="width: 48px">
              <div class="box" @click="handleSearch">
                <img
                  src="@/assets/img/search/icon_search.png"
                  alt=""
                  width="18px"
                  height="18px"
                />
              </div>
            </template>
          </el-input>
<!--          <span @click="$router.push('/box/search'); $store.state.searchType = 'findAdvertising'" class="header-box-search"-->
<!--            ><img-->
<!--              src="./../../../../assets/img/monitor/list/icon_add.png"-->
<!--              alt=""-->
<!--            />-->
<!--            添加监测</span>-->
        </div>
      </h3>
    </div>
    <div class="table-header">
      <div class="left">
        <div class="l-btn" :class="{ 'l-btn-hot': selectIndex == 0 }">
          <a href="javascript:;" @click="selectIndexFn(0)">监测竞品</a>
          <font>{{ allNumProduct > 10000 ? "10000+" : allNumProduct }}</font>
        </div>
        <div class="l-btn" :class="{ 'l-btn-hot': selectIndex == 1 }">
          <a href="javascript:;" @click="selectIndexFn(1)">监测关键词</a>
          <font>{{ allNumKeywords > 10000 ? "10000+" : allNumKeywords }}</font>
        </div>
      </div>
    </div>
    <Product
      ref="product"
      v-if="selectIndex == 0"
      :query_str="query_str"
      :platArr="platArr"
      @allNumProduct="allNumProductFn"
    />
    <Keywords
      ref="keywords"
      v-if="selectIndex == 1"
      :query_str="query_str"
      :platArr="platArr"
      @allNumKeywords="allNumKeywordsFn"
    />
  </div>
</template>

<script>
import Keywords from "@/views/box/monitor/list/keywords";
import Product from "@/views/box/monitor/list/product";
import { get_monitor_word_list } from "@/api/monitor";
import PinganAna from "pingansec-vue-ana";
import {
  get_monitor_word_result_list,
  get_val_by_key,
  set_val_by_key,
  unbind_wx,
  get_wx_bind_qrcode,
  get_user_info
} from "@/api/monitor";
export default {
  components: {
    Keywords,
    Product,
  },
  data() {
    return {
      userInfo:{
        bind_wx: false,
      },
      defaultFalse: false,
      WXNotice: false,
      MSNotice: false,
      show_ewm: false,
      ewm_url: '',
      selectIndex: 0,
      allNumProduct: 0,
      allNumKeywords: 0,
      query_str: null,
      platArr: [
        {
          label: "百度PC",
          value: 1,
          img: require("@/assets/img/companyDetails/baidu_search.png"),
        },
        {
          label: "百度移动",
          value: 2,
          img: require("@/assets/img/companyDetails/baidu_mobile.png"),
        },
        {
          label: "360PC",
          value: 3,
          img: require("@/assets/img/companyDetails/360_search.png"),
        },
        {
          label: "360移动",
          value: 4,
          img: require("@/assets/img/companyDetails/360_mobile.png"),
        },
        {
          label: "搜狗PC",
          value: 5,
          img: require("@/assets/img/companyDetails/sougou_search.png"),
        },
        {
          label: "搜狗移动",
          value: 6,
          img: require("@/assets/img/companyDetails/sougou_mobile.png"),
        },
        {
          label: "神马搜索",
          value: 7,
          img: require("@/assets/img/companyDetails/shenma_search.png"),
        },
      ],
      timer: null,
    };
  },
  created() {
    PinganAna.fire(3097)
    get_monitor_word_list({
      page: 1,
      limit: 10,
    }).then((res) => {
      let { result_code, message, data } = res.data;
      if (result_code == 0) {
        this.allNumKeywords = Number(data.cnt);
      }
    });
    this.getWXNotice()
    this.getMSNotice()
    this.getUserInfo();
    this.timer = setInterval(() => {
      this.getUserInfo();
    }, 3000)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    notice() {
      if(!this.userInfo.bind_wx) {
        this.$message({
          type: 'warning',
          message: '请先绑定微信！'
        })
      }
    },
    cancelBinding() {
      unbind_wx().then(res =>{
        if(res.data.result_code=='0') {
          this.$message({
            type:'success',
            message: '操作成功！'
          })
        }
      })
    },
    changeMSNotice() {
      let params = {
        key: 'send_message',
        val: this.MSNotice?'open':'close'
      }
      set_val_by_key(params).then(res =>{
        if (res.data.result_code == 0) {
          this.$message({
            type:'success',
            message: '操作成功！'
          });
          this.getMSNotice();
        }
      })
    },
    changeNotice() {
      let params = {
        key: 'wx_notice',
        val: this.WXNotice?'open':'close'
      }
      set_val_by_key(params).then(res =>{
        if (res.data.result_code == 0) {
          this.$message({
            type:'success',
            message: '操作成功！'
          });
          this.getWXNotice();
        }
      })
    },
    getUserInfo() {
      get_user_info().then((res) => {
        this.userInfo = res.data.data;
        if(this.userInfo.bind_wx) {
          this.show_ewm = false;
        }
        if(!this.userInfo.bind_wx) {
          this.getWXEWM();
        }
      });
    },
    getWXNotice() {
      let params = {
        key: 'wx_notice'
      }
      get_val_by_key(params).then(res =>{
        if(res.data.result_code==0) {
          this.WXNotice = res.data.data.wx_notice;
        }
      })
    },
    getMSNotice() {
      let params = {
        key: 'send_message'
      }
      get_val_by_key(params).then(res =>{
        if(res.data.result_code==0) {
          this.MSNotice = res.data.data.is_opened;
        }
      })
    },
    getWXEWM() {
      get_wx_bind_qrcode().then(res =>{
        if(res.data.result_code==0) {
          this.ewm_url = res.data.data.url
        }
      })
    },
    handleSearch() {
      if (this.selectIndex === 0) {
        this.$refs.product.init();
      } else {
        this.$refs.keywords.init();
      }
    },
    selectIndexFn(index) {
      this.selectIndex = index;
    },
    allNumProductFn(val) {
      this.allNumProduct = val;
    },
    allNumKeywordsFn(val) {
      this.allNumKeywords = val;
    },
  },
};
</script>

<style lang="scss" scoped>
#newest {
  width: 100%;
  height: 100%;
  .info_head {
    border-radius: 5px;
    background-color: #ffffff;
    .title {
      font-size: 16px;
      color: #333333;
      padding: 20px;
      padding-bottom: 24px;
      border-bottom: 1px solid #ebeff2;
    }
    .binding {
      width: 100%;
      margin-bottom: 10px;
      font-size: 12px;
      color: #999999;
      padding-bottom: 18px;
      .text {
        margin-top: 20px;
        border-top: 1px solid #ebeff2;
        padding-top: 15px;
        padding-left: 19px;
      }
      .notice_title {
        font-size: 14px;
        color: #333333;
        margin-right: 20px;
        .line {
          display: inline-block;
          background-color: #1f81f8;
          width: 3px;
          height: 13px;
          position: relative;
          top: 2px;
        }
      }
      .wx {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 19px;
        position: relative;
        .status {
          font-size: 14px;
          color: #333333;
        }
        .el-switch {
          margin-right: 12px;
        }
        img {
          width: 20px;
          height: 16px;
          margin-left: 13px;
          margin-right: 11px;
        }
        .click {
          color: #1f81f8;
          cursor: pointer;
        }
        .ewm {
          width: 140px;
          height: 176px;
          background-color: #ffffff;
          box-shadow: 0px 4px 10px 0px
          rgba(204, 204, 204, 0.3);
          border-radius: 6px;
          border: solid 1px #ebeff2;
          position: absolute;
          z-index: 10;
          left: 190px;
          top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          color: #1f81f8;
          font-size: 12px;
          img {
            width: 129px;
            height: 129px;
          }
        }
      }
      .ms {
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-left: 19px;
        .status {
          font-size: 14px;
          color: #333333;
        }
        img {
          width: 14px;
          height: 18px;
          margin-left: 13px;
          margin-right: 11px;
        }
        .el-switch {
          margin-right: 12px;
        }
      }
    }
  }
  .header {
    @extend .width-normal;
    background-color: $cffffff;
    border: solid 1px $cebeff2;
    border-bottom: none;
    h3 {
      @extend .width-normal;
      @extend .font-normal;
      height: 50px;
      font-size: $f16;
      line-height: 50px;
      color: $c333333;
      border-bottom: 1px solid $cebeff2;
      text-indent: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-box {
        display: flex;
        align-items: center;
        margin-right: 50px;
        .header-box-search {
          display: block;
          margin-left: 10px;
          margin-right: 20px;
          color: $cffffff;
          width: 100px;
          height: 33px;
          background-color: $c1f81f8;
          border-radius: $r6;
          border: solid 1px $c318bf9;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            @extend .width-normal;
            width: 12px;
            margin-right: 6px;
          }
          &:active {
            background-color: $c368ef9;
          }
        }
      }
    }
  }
  .table-header {
    @extend .width-normal;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $cffffff;
    .left {
      height: 100%;
      margin-left: 20px;
      display: flex;
      align-items: center;
      .l-btn {
        height: 100%;
        margin-right: 42px;
        a {
          @extend .font-normal;
          display: inline-block;
          line-height: 48px;
          font-size: $f16;
          color: $c333333;
          border-bottom: 2px solid $cffffff;
          margin-right: 4px;
        }
        font {
          color: $c999999;
        }
      }
      .l-btn-hot {
        a {
          border-bottom: 2px solid $c1f81f8;
          font-weight: bold;
        }
        font {
          color: $c1f81f8;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#newest .el-input-group {
  width: 350px;
}
#newest .el-input-group__append,
.el-input-group__prepend {
  padding: 0;
  background-color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #e9f3fd;
  }
  &:active {
    background-color: #f6f9fe;
  }
  .box {
    width: 46px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.el-date-editor {
  width: 250px !important;
}

.header-box .el-input__inner {
  height: 33px;
}
</style>
